
export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}

export const generateItems = (count, creator) => {
  const result = []
  for (let i = 0; i < count; i++) {
    result.push(creator(i))
  }
  return result
}

export const getDescriptionCode = (descriptionArray) => {
  let shortcode = '';
  descriptionArray.forEach((description, index) => {
    let currentCode = `d${index + 1}`;

    if (description.image && description.image.length !== 0) {
      currentCode += '-i';
    } else if (description.title && description.title.length !== 0) {
      currentCode += '-t';
    } else {
      currentCode += '-0';
    }

    shortcode += currentCode;
  });
  if (descriptionArray.length < 2) {
    for (let i = descriptionArray.length; i < 2; i++) {
      shortcode += `-d${i + 1}-0`;
    }
  }
  return shortcode;
};


export const getOptions = (currentQuestion) => {
  let shortcode = '';

  currentQuestion.options.forEach((option, index) => {
    if (option.image != null && option.title != null && option.image != "") {
      shortcode += `-o${index + 1}-i`;
    }
    else if (option.title != null && option.title.length != 0 && option.title != "") {
      shortcode += `-o${index + 1}-t`;
    } else if (option.image != null && option.image.length != 0 && option.image != "") {
      shortcode += `-o${index + 1}-i`;
    }

    else {
      shortcode += `-o${index + 1}-0`;
    }
  });

  const optionCount = currentQuestion.options.length;
  if (optionCount <= 4) {
    for (let i = optionCount + 1; i <= 6; i++) {
      shortcode += `-o${i}-0`;
    }
  }
  return shortcode;
}


export const getValidOptionsLength = (options) => {
  const validOptions = options.filter(option => option && option.title && option.title.trim() !== '');
  return validOptions.length;
}